import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Link, graphql } from 'gatsby'
import SectionHead from './SectionHead'
import ExhibitionDates from './ExhibitionDates'

import Reveal from 'react-reveal/Reveal';


export default class ExhibitionsPage extends React.Component {
  
  checkIfEvents(posts, range) {
    this.showEvents = false
    posts.map(({ node: post }) => (
      this.checkDate(post.acf.start_date, post.acf.end_date) === range ?
        this.showEvents = true
      : false
    ))
    return this.showEvents
  }

  checkDate(startDateInput, endDateInput) {
    var today = new Date();
    var startDate = new Date(startDateInput);
    var endDate = new Date(endDateInput);
    if (today > startDate && today < endDate ){
      this.conclusion = 'current';
    } else if (today > startDate && today > endDate ) {
      this.conclusion = 'past';
    } else {
      this.conclusion = 'future';
    }
    return this.conclusion;
  }

  render() {
    const { posts, title, range, link, url, full } = this.props

    if (this.checkIfEvents(posts, range)) {
      return (
        <div>
        <SectionHead title={title} link={link} url={url} />
          <section className="exhibitions">
            <div className="container content">
              {posts.map(({ node: post }) => (
                this.checkDate(post.acf.start_date, post.acf.end_date) === range &&
                  <div key={post.id} className={ full ? "columns exhibitions__single is-vcentered" : "columns exhibitions__single exhibitions__single--compact is-vcentered" }>
                    { post.acf.image && post.acf.image.localFile.childImageSharp ?
                      <Reveal effect="fadeInUp">
                        <div className="image column is-6-tablet is-desktop-8">
                          { full 
                            ? <Img 
                            fluid={post.acf.image.localFile.childImageSharp.fluid} 
                            key={post.acf.image.id}/>
                            : <Img 
                            fluid={post.acf.image.localFile.childImageSharp.fluid} 
                            key={post.acf.image.id}/> }    
                        </div>
                      </Reveal>
                    : false }
                    <Reveal effect="fadeInUp">
                      <div className="column is-4 is-5-tablet is-offset-1-tablet is-offset-1-desktop">
                          <Link className="title is-size-2 is-italic has-text-primary" to={ "/exhibitions/" + post.slug}>
                            {post.title}
                          </Link><br/>
                          <ExhibitionDates start={post.acf.start_date} end={post.acf.end_date} location={post.acf.location} />

                          { full 
                            ? <div
                            dangerouslySetInnerHTML={{
                              __html: post.excerpt
                            }}
                            />
                            : false }

                          <Link className="has-text-primary" to={ "/exhibitions/" + post.slug}>
                            Read more
                          </Link>
                      </div>
                    </Reveal>
                  </div>
              ))}
            </div>
          </section>
        </div>
      )
    } else {
      return (
        <>
          <SectionHead title={'No <span style="text-transform:lowercase">' + title + '</span> to display.'} link={link} url={url} />
          <div className="container content">
        <h2><Link to={range === 'current' ? '/exhibitions/upcoming' : '/exhibitions/current' }>See {range === 'current' ? 'upcoming' : 'current'} exhibitions.</Link></h2>
          </div>
        </>
      )
    }
  }
}

ExhibitionsPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  range: PropTypes.string,
  link: PropTypes.string,
  url: PropTypes.string,
  full: PropTypes.string
}

export const pageQuery = graphql`
  fragment ExhibitionListFields on wordpress__wp_exhibitions {
    id
    title
    excerpt
    date(formatString: "MMMM DD, YYYY")
    slug
    acf {
      location
      start_date(formatString: "MMMM DD, YYYY")
      end_date(formatString: "MMMM DD, YYYY")
      image {
        id
        localFile {
          childImageSharp {
            # Try editing the "maxWidth" value to generate resized images.
            fluid(maxWidth: 1680) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
